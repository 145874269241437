<template>
  <div class="pa-3">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-dialog
        v-model="showDetail"
        :max-width="'1000px'"
        persistent
        transition="dialog-top-transition"
        @keydown.esc="close"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>

            <v-spacer></v-spacer>
            <v-layout row justify-center align-center v-if="progress">
              <v-progress-circular
                indeterminate
                :size="30"
                :width="7"
                color="purple"
              ></v-progress-circular>
            </v-layout>

            <v-btn
              color="blue darken-1"
              @click="save"
              :disabled="save_disable"
              class="mx-2"
              small
            >
              Enregistrer
            </v-btn>
            <v-btn color="blue darken-1" @click.stop="close" small>
              Annuler
            </v-btn>
          </v-card-title>

          <v-card-text>
            <v-container fluid>
              <v-row dense>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                  v-if="
                    mvm_type == 19 &&
                    !allproduct &&
                    editedItem.id < 0 &&
                    !client_unique &&
                    !isloading
                  "
                >
                  <v-autocomplete
                    v-model="client_id"
                    :item-value="'tier_id'"
                    :items="products"
                    :filter="customFilter"
                    label="Filtre par client"
                    :readonly="!modify || form_list.length > 0"
                    clearable
                    dense
                  >
                    <template v-slot:selection="{ item }">
                      <!-- HTML that describe how select should render selected items -->
                      {{
                        (item.tier_code ? item.tier_code + "-" : "") +
                        item.tier_name
                      }}
                    </template>
                    <template v-slot:item="{ item }">
                      {{
                        (item.tier_code ? item.tier_code + "-" : "") +
                        item.tier_name
                      }}
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                  v-if="
                    ((mvm_type == 1 && !isdossier) || mvm_type == 19) &&
                    !allproduct &&
                    editedItem.id < 0 &&
                    !cmd_unique &&
                    !isloading
                  "
                >
                  <v-autocomplete
                    v-model="cmd_id"
                    :item-value="'cmd_id'"
                    :items="products_cmd"
                    :filter="customFilter"
                    label="Filtre par commande"
                    :readonly="!modify || form_list.length > 0"
                    clearable
                    dense
                  >
                    <template v-slot:selection="{ item }">
                      <!-- HTML that describe how select should render selected items -->
                      {{
                        item.nocmd +
                        " du " +
                        datefr(item.date_cmd) +
                        " - " +
                        (mvm_type == 19 ? "Client " : "Fournisseur ") +
                        item.tier_name
                      }}
                    </template>
                    <template v-slot:item="{ item }">
                      {{
                        item.nocmd +
                        " du " +
                        datefr(item.date_cmd) +
                        " - " +
                        (mvm_type == 19 ? "Client " : "Fournisseur ") +
                        item.tier_name
                      }}
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="10"
                  v-if="
                    (mvm_type == 1 ||
                      mvm_type == 17 ||
                      mvm_type == 19 ||
                      isdossier) &&
                    !allproduct
                  "
                >
                  <v-autocomplete
                    v-model="editedItem.cmd_det_id"
                    :item-value="isdossier ? 'cmd_detail_id' : 'id'"
                    :items="
                      mvm_type == 19 || (mvm_type == 1 && !isdossier)
                        ? products_list
                        : products
                    "
                    :filter="customFilter"
                    label="Produit"
                    :readonly="!modify || form_list.length > 0"
                    :rules="[(v) => !!v || 'Produit obligatoire']"
                    @change="produit_change"
                    dense
                    :loading="isloading"
                  >
                    <template v-slot:selection="{ item }">
                      <!-- HTML that describe how select should render selected items -->
                      {{
                        (item.product_code ? item.product_code + "-" : "") +
                        item.label
                      }}
                    </template>
                    <template v-slot:item="{ item }">
                      <v-list max-width="700">
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="
                              (item.product_code
                                ? item.product_code + '-'
                                : '') + item.label
                            "
                          ></v-list-item-title>
                          <v-list-item-subtitle
                            v-html="'Ref. : ' + item.ref"
                            v-if="item.ref"
                          ></v-list-item-subtitle>
                          <v-list-item-subtitle
                            v-html="
                              'Client : ' +
                              item.tier_code +
                              '-' +
                              item.tier_name
                            "
                            v-if="mvm_type == 19"
                          ></v-list-item-subtitle>
                          <v-list-item-subtitle
                            v-html="
                              'Commande N° : ' +
                              item.nocmd +
                              ' du ' +
                              datefr(item.date_cmd)
                            "
                            v-if="!isdossier"
                          ></v-list-item-subtitle>
                          <v-list-item-subtitle
                            v-html="
                              'Qte ' +
                              (isdossier ? 'Arrivage ' : 'Commandée ') +
                              (item.qte ? numberWithSpace(item.qte) : 0) +
                              ' ' +
                              (item.unit ? item.unit : '')
                            "
                          ></v-list-item-subtitle>
                          <v-list-item-subtitle>
                            <v-row>
                              <v-col cols="12" sm="6" md="6">
                                <span>
                                  {{
                                    "Qte Receptionnée : " +
                                    numberWithSpace(
                                      isdossier
                                        ? item.qte_rcpt
                                        : iscmd
                                        ? item.qte_liv
                                        : item.qte_rcpt
                                    ) +
                                    " " +
                                    (item.unit ? item.unit : "")
                                  }}
                                </span>
                              </v-col>
                              <v-col cols="12" sm="6" md="6">
                                <v-progress-linear
                                  :value="
                                    isdossier || mvm_type == 19
                                      ? item.prc_rcpt
                                      : item.prc_liv
                                  "
                                  color="light-green darken-2"
                                  height="25"
                                  rounded
                                >
                                  <template v-slot:default>
                                    {{
                                      (isdossier || mvm_type == 19
                                        ? item.prc_rcpt
                                        : item.prc_liv) + "% "
                                    }}
                                  </template>
                                </v-progress-linear>
                              </v-col>
                            </v-row>
                          </v-list-item-subtitle>
                          <v-list-item-subtitle
                            v-if="mvm_type == 1 || mvm_type == 19"
                            :class="
                              !item.stock_cmpt || item.stock_cmpt == 0
                                ? 'red'
                                : 'green'
                            "
                            v-html="
                              'Stock : ' +
                              (item.stock_cmpt
                                ? numberWithSpace(item.stock_cmpt)
                                : 0) +
                              ' ' +
                              (item.unit ? item.unit : '')
                            "
                          ></v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="10" v-else>
                  <v-autocomplete
                    v-model="editedItem.produit_id"
                    :item-value="'produit_id'"
                    :items="products"
                    :filter="customFilter"
                    label="Produit"
                    :readonly="!modify || form_list.length > 0"
                    :rules="[(v) => !!v || 'Produit obligatoire']"
                    @change="produit_change"
                    dense
                  >
                    <template v-slot:selection="{ item }">
                      <!-- HTML that describe how select should render selected items -->
                      {{
                        (item.product_code ? item.product_code + "-" : "") +
                        item.product_name
                      }}
                    </template>
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="
                            (item.product_code ? item.product_code + '-' : '') +
                            item.product_name
                          "
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-html="'Ref. : ' + item.ref"
                          v-if="item.ref"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                  md="12"
                  v-if="
                    editedItem.produit_id &&
                    !(mvm_type == 19 && articles.length == 1)
                  "
                >
                  <v-autocomplete
                    v-model="editedItem.article_id"
                    :item-value="article_field"
                    :items="articles"
                    :filter="customFilter"
                    label="Article"
                    :readonly="!modify || form_list.length > 0"
                    @change="article_change"
                    :rules="[(v) => !!v || 'Article obligatoire']"
                    dense
                    :loading="data_load"
                  >
                    <template v-slot:selection="{ item }">
                      <!-- HTML that describe how select should render selected items -->
                      {{
                        (item.ref_ext ? "(" + item.ref_ext + ")" + "-" : "") +
                        (mvm_type == 18 ? item.code_article : item.code) +
                        "-" +
                        item.label
                      }}
                    </template>
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="
                            (mvm_type == 18 ? item.code_article : item.code) +
                            '-' +
                            item.label
                          "
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-html="
                            importdetails.length > 0
                              ? 'Arrivage : ' +
                                (item.qte ? item.qte : 0) +
                                ' ' +
                                (item.unit ? item.unit : '')
                              : ''
                          "
                        ></v-list-item-subtitle>
                        <v-list-item-subtitle>
                          {{ item.ref_ext ? "Ref. Ext. " + item.ref_ext : "" }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                          {{
                            item.tier_name
                              ? "Fournisseur : " + item.tier_name
                              : ""
                          }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                          <v-row>
                            <v-col cols="12" sm="6" md="6">
                              <span>
                                {{
                                  "Qte Receptionnée : " +
                                  numberWithSpace(item.qte_rcpt) +
                                  " " +
                                  (item.unit ? item.unit : "")
                                }}
                              </span>
                            </v-col>
                          </v-row>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col
                  cols="12"
                  sm="2"
                  md="2"
                  v-if="
                    editedItem.produit_id &&
                    (!editedItem.pu ||
                      $store.state.auth.includes('01027') ||
                      $store.state.isadmin)
                  "
                >
                  <v-text-field
                    autocomplete="off"
                    ref="pu"
                    class="inputPrice"
                    type="number"
                    v-model.number="editedItem.pu"
                    :readonly="
                      iscmd ||
                      isdossier ||
                      !(
                        $store.state.auth.includes('02027') ||
                        $store.state.isadmin
                      ) ||
                      !modify
                    "
                    :label="
                      mvm_type == 18 || mvm_type == 19
                        ? 'Prix de revient'
                        : 'P.U.(DZD)'
                    "
                    dense
                    hide-spin-buttons
                    :rules="[
                      (v) =>
                        !!v ||
                        (mvm_type == 18 || mvm_type == 19
                          ? 'Prix de revient'
                          : 'Prix unitaire') + ' obligatoire',
                    ]"
                    @focus="$event.target.select()"
                    @keydown.enter="$refs.qte.focus()"
                  ></v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                  md="2"
                  v-if="
                    ($store.state.auth.includes('02027') ||
                      $store.state.isadmin) &&
                    remise
                  "
                >
                  <v-text-field
                    autocomplete="off"
                    class="inputPrice"
                    type="number"
                    v-model.number="editedItem.remise"
                    :readonly="
                      !(
                        $store.state.auth.includes('02027') ||
                        $store.state.isadmin
                      ) || !modify
                    "
                    label="Remise(%)"
                    :rules="[
                      (v) =>
                        !v || (v > 0 && v <= 100) || 'Valeur entre 1 et 100',
                    ]"
                    dense
                    hide-spin-buttons
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="3" md="3" v-if="editedItem.service == '0'">
                  <v-autocomplete
                    ref="mvmto"
                    v-model="mvm_to"
                    item-value="id"
                    item-text="label"
                    :items="depots"
                    :readonly="!modify || form_list.length > 0"
                    label="Depot de Destination"
                    :rules="[
                      (v) => !!v || 'Depot obligatoire',
                      (v) =>
                        ligne_exist(
                          iscmd && !linked && !isdossier
                            ? editedItem.cmd_det_id
                            : editedItem.article_id,
                          v
                        ) == false || 'Ligne existe!',
                    ]"
                    @change="depot_change"
                    dense
                  >
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6" md="2">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        autocomplete="off"
                        ref="qte"
                        class="inputPrice"
                        type="number"
                        dense
                        v-model.number="editedItem.qte"
                        :label="'Quantité '"
                        :rules="[
                          (v) => !!v || 'Quantité obligatoire',
                          (v) =>
                            parseFloat(v) <= parseFloat(qte_max) ||
                            (!iscmd && !isdossier) ||
                            mvm_type == 19 ||
                            !modify ||
                            'Maximum autorisé ' + qte_max,
                        ]"
                        :hint="
                          iscmd || isdossier
                            ? 'Reste à Receptionner ' +
                              (qte_rst - (editedItem.qte ? editedItem.qte : 0))
                            : ''
                        "
                        v-bind="attrs"
                        v-on="on"
                        :readonly="
                          editedItem.bylist == '1' ||
                          (editedItem.bylist == '0' && depot.NbRack > 0) ||
                          !modify
                        "
                        @keydown.enter="save"
                        hide-spin-buttons
                        :disabled="
                          (!mvm_to && editedItem.service == '0') || save_disable
                        "
                        @focus="$event.target.select()"
                      ></v-text-field>
                    </template>
                    <div v-if="iscmd || isdossier">
                      <li>
                        Quantité
                        {{ numberWithSpace(qte) }}
                      </li>
                      <li>
                        {{
                          "Quantité Receptionnée " +
                          numberWithSpace(
                            editedItem.fk_unit == editedItem.fk_unit_product
                              ? qte_liv.toFixed(precision)
                              : qte_liv
                          )
                        }}
                      </li>
                    </div>
                  </v-tooltip>
                </v-col>
                <v-col cols="12" sm="1" md="2">
                  <v-select
                    ref="unite"
                    :items="units_list"
                    v-model="editedItem.fk_unit"
                    item-text="name"
                    item-value="id"
                    label="Unité de Mesure"
                    :readonly="
                      iscmd ||
                      isdossier ||
                      mvm_type == 18 ||
                      mvm_type == 19 ||
                      !modify
                    "
                    :rules="[(v) => !!v || 'Unité de Mesure obligatoire']"
                    @change="unit_change"
                    dense
                  >
                  </v-select>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="1"
                  v-if="
                    editedItem.produit_id &&
                    (!editedItem.pu ||
                      $store.state.auth.includes('01027') ||
                      $store.state.isadmin) &&
                    mvm.country_id == 13
                  "
                >
                  <v-text-field
                    autocomplete="off"
                    ref="tva"
                    class="inputPrice"
                    type="number"
                    dense
                    v-model.number="editedItem.tva_tx"
                    label="TVA"
                    :readonly="iscmd || isdossier || !modify"
                    :rules="[
                      (v) => !v || parseFloat(v) > 0 || 'Valeur incorrecte',
                    ]"
                    hide-spin-buttons
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="1" md="12">
                  <v-text-field
                    autocomplete="off"
                    ref="comment"
                    dense
                    v-model="editedItem.comment"
                    label="Commentaire"
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                  md="3"
                  v-if="mvm_to && depot.NbRack > 0 && editedItem.bylist == '1'"
                >
                  <v-autocomplete
                    ref="boxs"
                    v-model="editedItem.box_id"
                    item-value="id"
                    :items="boxs_list"
                    :readonly="!modify"
                    :filter="customFilter"
                    :label="(depot.box ? depot.box : 'Box') + ' Par defaut'"
                    @change="box_change"
                    clearable
                    dense
                  >
                    <template v-slot:selection="{ item }">
                      <!-- HTML that describe how select should render selected items -->
                      {{ item.box }}
                    </template>
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ item.box }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ depot.rack + " " + item.rack }}
                        </v-list-item-subtitle>

                        <v-list-item-subtitle>
                          {{ "Stock " + item.stock_cmpt.toFixed(2) }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                          {{ "Nb Article " + item.nbarticle }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  md="12 "
                  v-if="
                    (editedItem.bylist == '1' ||
                      (editedItem.bylist == '0' && depot.NbRack > 0)) &&
                    editedItem.article_id > 0 &&
                    mvm_to > 0
                  "
                >
                  <listitems
                    :list="form_list"
                    :headers="
                      editedItem.bylist == '1' ? colisheaders : boxheaders
                    "
                    :title="list_title"
                    :master="true"
                    :add="true"
                    :del="true"
                    :Update="true"
                    :showstd="true"
                    :Total="true"
                    :print="true"
                    :exp_excel="true"
                    :imp_excel="editedItem.bylist == '1'"
                    @excel_import="excel_import"
                    :key="frml"
                    @delete="del_item"
                    @add="add_item"
                    :multiple="false"
                    @selected_rows="selected_right_change"
                    :laoding="listload"
                    :ipg="5"
                  >
                  </listitems>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-form>

    <confirmdialog ref="confirm" />
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </div>
</template>

<script>
function onlyUnique(value, index, array) {
  return array.indexOf(value) === index;
}
// function paddy(num, padlen, padchar) {
//   var pad_char = typeof padchar !== "undefined" ? padchar : "0";
//   var pad = new Array(1 + padlen).join(pad_char);
//   return (pad + num).slice(-pad.length);
// }
// function countString(str, letter, pos) {
//   let count = 0;
//   let lastpos = -1;
//   // looping through the items
//   for (let i = pos; i < str.length; i++) {
//     // check if the character is at that position
//     if (str.charAt(i) == letter) {
//       if (lastpos == -1 || lastpos + 1 == i) {
//         count += 1;
//         lastpos = i;
//       }
//     }
//   }
//   return count;
// }

import MVM_DETAIL_UPDATE from "../graphql/Mouvment/MVM_DETAIL_UPDATE.gql";
import CMDS_RCPT from "../graphql/Mouvment/CMDS_RCPT.gql";

import PRODUCT_LIST_MVM from "../graphql/Product/PRODUCT_LIST_MVM.gql";
import { remise, colisbl } from "print/data.js";
export default {
  components: {
    confirmdialog: () => import("./ConfirmDialog.vue"),
    listitems: () => import("./ListItems.vue"),
  },
  name: "mvmrcptform",
  props: {
    item: Object,
    items: Array,
    showForm: Boolean,
    areg: { type: Boolean, default: false },
    cmddetails: Array,
    tier_articles: Array,
    mvm_type: Number,
    importdetails: Array,
    attributes: Array,
    mvm: Object,
    modify: Boolean,
    units: Array,
    boxs: Array,
    isloading: Boolean,
  },
  data: () => ({
    num_seq: 1,
    code: "",
    client_id: null,
    cmd_id: null,
    allproduct: false,
    data_load: false,
    kflist: 100,
    adding: false,
    deleting: false,
    productload: false,
    imp: false,
    title: "Liste Détaillée",
    listload: false,
    save_disable: false,
    isListClose: true,
    list: false,
    datepicker1: false,
    progress: false,
    valid: true,
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    defaultItem: {},
    editedItem: {},
    combovalue: "",
    isProductClosed: true,
    product: {},
    depot: {},
    list_title: "Articles",
    article_field: null,
    linked: false,
    qte: 0,
    qte_liv: 0,
    qte_ret: 0,
    qte_rst: 0,
    qte_max: 0,
    qte_depot: 0,
    tol: 0,
    mvm_to: "",
    mvm_from: "",
    precision: 2,
    colisheaders2: [
      {
        text: "No",
        value: "no",
        selected: true,
        totlib: true,
      },

      {
        text: "Barcode",
        value: "ref",
        selected: true,
        edit: true,
        type: "text",
        sm: 6,
        md: 6,
        rules: [(v) => !!v || "Code obligatoire"],
      },
      {
        text: "Quantité",
        value: "qte",
        total: "qte",
        slot: "href22",
        align: "end",
        selected: true,
        edit: true,
        type: "qte",
        dec: 2,
        sm: 3,
        md: 3,
        rules: [(v) => !!v || "Quantité obligatoire"],
      },

      {
        text: "Date de péremption",
        value: "expire_date",
        selected: true,
        slot: "date",
        edit: false,
        type: "date",
        date_max: null,
        dense: false,
        rules: [(v) => !!v || "Date de péremption obligatoire"],
        hiden: true,
        sm: 4,
        md: 4,
      },
      {
        text: "Box",
        value: "box_id",
        slot: "simple-select",
        selected: true,
        hiden: true,
        edit: false,
        type: "autocomplete",
        item_value: "id",
        item_text: "box",
        subtitle1: "rack",
        subtitle1_label: "Rack",
        subtitle2: "stock_cmpt",
        subtitle2_label: "Stock",
        subtitle3: "nbarticle",
        subtitle3_label: "Nb : ",
        select_list: [],
        show_list: [],
        default: "",
        sm: 6,
        md: 6,
        rules: [(v) => !!v || "Box obligatoire"],
      },
      {
        text: "Comment",
        value: "comment",
        selected: true,
        edit: true,
        type: "text",
        sm: 12,
        md: 12,
      },
    ],
    colisheaders: [],
    boxheaders: [
      {
        text: "No",
        value: "no",
        selected: true,
        totlib: true,
      },
      {
        text: "Box",
        value: "box_id",
        slot: "simple-select",
        selected: true,
        edit: true,
        type: "autocomplete",
        item_value: "id",
        item_text: "box",
        subtitle1: "rack",
        subtitle1_label: "Rack",
        subtitle2: "stock_cmpt",
        subtitle2_label: "Stock : ",
        subtitle3: "nbarticle",
        subtitle3_label: "Nb : ",
        select_list: [],
        show_list: [],
        sm: 6,
        md: 6,
        rules: [(v) => !!v || "Box obligatoire"],
      },
      {
        text: "Quantité",
        value: "qte",
        total: "qte",
        slot: "href22",
        align: "end",
        selected: true,
        edit: true,
        type: "number",
        dec: 2,
        sm: 3,
        md: 3,
        rules: [(v) => !!v || "Quantité obligatoire"],
      },
    ],
    form_list: [],
    prdslist: [],
    selected_left: [],
    selected_right: [],
    articles: [],
    units_list: [],
    frml: 600,
    cs: 0,
  }),

  computed: {
    client_unique() {
      var unique = [];
      unique = this.products.map((elm) => elm.tier_id).filter(onlyUnique);
      return unique.length == 1;
    },
    colis_unit() {
      let c = "item";
      let i = this.units.findIndex(
        (elm) => elm.id == this.product.fk_unit_colis
      );
      if (i >= 0) c = this.units[i].name;
      return c;
    },
    cmd_unique() {
      var unique = this.products_cmd
        .map((elm) => elm.cmd_id)
        .filter(onlyUnique);
      return unique.length == 1;
    },
    remise() {
      return remise;
    },
    depots() {
      return this.$store.state.depots.filter(
        (elm) => elm.ChildCount == 0 && elm.type_id == 2
      );
    },
    sumlist_selected() {
      let list = [];
      list = this.form_list ? this.form_list : [];
      return list
        ? parseFloat(list.reduce((a, b) => a + (b["qte"] || 0), 0))
        : 0;
    },

    attributs_list() {
      return this.attributes.filter((elm) => elm.type_attr == "colis");
    },
    iscmd() {
      let ok =
        (this.mvm_type == 1 || this.mvm_type == 17 || this.mvm_type == 19) &&
        !this.allproduct;
      return ok;
    },
    isdossier() {
      let ok = false;
      if (this.importdetails) ok = this.importdetails.length > 0;
      return ok;
    },

    products() {
      let list = [];
      if (this.isdossier) list = this.importdetails;
      else if (this.iscmd) {
        list = this.cmddetails;
      } else list = this.tier_articles;
      if (list)
        list = list.filter(
          (elm) => elm.service == (this.mvm_type == 17 ? "1" : "0")
        );
      return list;
    },
    products_cmd() {
      let l = [];
      if (this.products) {
        l = this.products;
        if (this.client_id)
          l = this.products.filter((elm) => elm.tier_id == this.client_id);
      }
      return l;
    },
    products_list() {
      let l = [];
      if (this.products_cmd) {
        l = this.products_cmd;
        if (this.cmd_id)
          l = this.products.filter((elm) => elm.cmd_id == this.cmd_id);
      }
      return l;
    },

    boxs_list() {
      let list = [];
      if (this.boxs)
        list = this.boxs.filter((elm) => elm.depot_id == this.mvm_to);
      return list;
    },
    showDetail() {
      return this.showForm;
    },
    formTitle() {
      if (this.item)
        return this.item.id < 0
          ? "Nouvelle Ligne "
          : "Ligne N° ".concat(parseInt(this.item.index) + 1);
      else return "";
    },
  },
  watch: {
    form_list: {
      handler() {
        if (
          this.editedItem.bylist == "1" ||
          (this.editedItem.bylist == "0" && this.depot.NbRack > 0)
        ) {
          this.editedItem.qte =
            this.editedItem.fk_unit == this.editedItem.fk_unit_product
              ? this.sumlist_selected.toFixed(this.precision)
              : this.sumlist_selected.toFixed(2);
          if (
            colisbl &&
            this.editedItem.bylist == "1" &&
            this.editedItem.id < 0
          ) {
            this.editedItem.description = "";
            this.editedItem.colis_json = [];
            if (this.form_list) {
              this.form_list.forEach((element) => {
                let i = this.editedItem.colis_json.findIndex(
                  (elm) =>
                    elm.article_id == element.article_id &&
                    elm.qte == element.qte
                );
                if (i >= 0)
                  this.editedItem.colis_json[i].nb =
                    this.editedItem.colis_json[i].nb + 1;
                else
                  this.editedItem.colis_json.push({
                    article_id: element.article_id,
                    label: element.label,
                    qte: element.qte,
                    nb: 1,
                  });
              });
            }
            let unit = this.product.unit ? this.product.unit : "";
            if (this.mvm_type == 1 && this.product.unit_buy)
              unit = this.product.unit_buy;
            this.editedItem.description = this.get_desc(
              this.editedItem.colis_json,
              this.editedItem.qte,
              this.colis_unit,
              unit
            );
          }
        }
      },
      deep: true,
    },
  },

  created() {},
  async mounted() {
    this.allproduct =
      this.areg &&
      (this.mvm_type == 1 || this.mvm_type == 17 || this.mvm_type == 19) &&
      !this.isdossier;
    if (this.item) {
      this.editedItem = Object.assign({}, this.item);

      if (this.item.id > 0) {
        this.mvm_to = this.editedItem.depot_id;
        this.produit_change();
        this.article_change();
        this.depot_change();
      }
    }
  },

  methods: {
    get_desc(list, qte, colis_unit, unit) {
      let desc = "";
      if (list.length > 0) {
        var unique = list.map((elm) => elm.article_id).filter(onlyUnique);
        if (unique.length > 1) {
          unique.forEach((elm2) => {
            let l = list.filter((elm) => elm.article_id == elm2);
            if (l.length > 0) {
              desc = desc + " " + l[0].label + "\n";
              list
                .filter((elm) => elm.article_id == l[0].article_id)
                .forEach((element) => {
                  desc =
                    desc +
                    element.nb.toString() +
                    " " +
                    colis_unit +
                    (element.nb > 1 ? "s" : "") +
                    " / " +
                    element.qte.toFixed(this.precision) +
                    " " +
                    unit +
                    " " +
                    "\n";
                });
            }
          });
        } else
          list.forEach((element) => {
            desc =
              desc +
              element.nb.toString() +
              " " +
              colis_unit +
              (element.nb > 1 ? "s" : "") +
              " / " +
              element.qte.toFixed(this.precision) +
              " " +
              unit +
              " " +
              "\n";
          });
        if (list.length > 0 && unique.length == 1)
          desc =
            desc +
            "Soit " +
            list.reduce((a, b) => a + (b["nb"] || 0), 0) +
            " " +
            colis_unit +
            "s pour un total de " +
            qte +
            " " +
            unit;
      }
      return desc;
    },
    datefr(date, time = false, long = true) {
      let t = "";
      let option = {
        year: "numeric",
        month: long ? "long" : "numeric",
        day: "numeric",
      };
      if (time)
        option = {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        };
      if (date) {
        var result = new Date(date);
        t = result.toLocaleDateString("fr-FR", option);
      }
      return t;
    },
    box_change() {
      this.colisheaders2[4].default = this.editedItem.box_id;
    },
    depot_change() {
      let i = this.depots.findIndex((elm) => elm.id == this.mvm_to);
      if (i >= 0) {
        this.depot = this.depots[i];
        this.list_title =
          this.editedItem.bylist == "1" ? "Articles" : this.depot.box;
        this.colisheaders2[4].text = this.depot.box ? this.depot.box : "Box";
        this.colisheaders2[4].rules = [
          (v) => !!v || this.colisheaders2[4].text + " obligatoire",
        ];
        this.colisheaders2[4].subtitle1_label = this.depot.rack
          ? this.depot.rack
          : "Rack";
        this.colisheaders2[4].hiden = this.depot.NbRack == 0;
        this.colisheaders2[4].edit = this.depot.NbRack > 0;
        this.colisheaders2[4].select_list = this.boxs_list;
        this.colisheaders2[4].show_list = this.boxs_list;

        this.boxheaders[1].text = this.colisheaders2[4].text;
        this.boxheaders[1].rules = this.colisheaders2[4].rules;
        this.boxheaders[1].subtitle1_label =
          this.colisheaders2[4].subtitle1_label;

        this.boxheaders[1].show_list = this.boxs_list;
        let u = "";
        let z;
        if (this.units)
          z = this.units.findIndex((elm) => elm.id == this.editedItem.fk_unit);
        if (z >= 0) u = "(" + this.units[z].name + ")";
        this.boxheaders[2].text = "Quantité " + u;
        this.boxheaders[2].dec = this.precision;
        if (this.editedItem.bylist == "1") this.init_list();
        if (
          this.editedItem.bylist == "0" &&
          this.depot.NbRack > 0 &&
          this.editedItem.id > 0
        ) {
          this.form_list = this.editedItem.boxs;
          this.frml++;
        }

        this.boxheaders[1].select_list = this.boxs_list.filter(
          (elm) => !this.form_list.map((elm) => elm.box_id).includes(elm.id)
        );
      }
    },

    unit_change() {
      let k = this.units.findIndex(
        (elm) => elm.id == this.editedItem.fk_unit_product
      );
      this.editedItem.factor = this.editedItem.product_factor;
      if (k >= 0) {
        let cat_buy;
        let cat_product = this.units[k].category_id;
        let j = this.units.findIndex(
          (elm) => elm.id == this.editedItem.fk_unit
        );
        if (j >= 0) cat_buy = this.units[j].category_id;
        if (cat_buy == cat_product && this.units[k].factor)
          this.editedItem.factor = this.units[j].factor / this.units[k].factor;
      }
    },
    get_units() {
      if (this.iscmd || this.isdossier)
        this.units_list = this.units.filter((elm) => elm.uom_type == "m");
      else {
        let k = this.units.findIndex(
          (elm) => elm.id == this.editedItem.fk_unit_product
        );
        if (k >= 0) {
          let c = this.units[k].category_id;
          this.units_list = this.units.filter((elm) => elm.category_id == c);
        }
        k = this.units_list.findIndex(
          (elm) => elm.id == this.editedItem.fk_unit_buy
        );
        if (k == -1) {
          let i = this.units.findIndex(
            (elm) => elm.id == this.editedItem.fk_unit_buy
          );
          if (i >= 0)
            this.units_list.push({
              id: this.units[i].id,
              name: this.units[i].name,
            });
        }
      }
    },

    async set_articles() {
      this.articles = [];
      if (this.product) {
        this.linked = false;
        let i;
        if (this.iscmd || this.isdossier) {
          i = this.products.findIndex(
            (elm) =>
              elm.produit_id == this.editedItem.produit_id &&
              elm.article_id == null
          );
          if (i >= 0) {
            this.article_field = "id";
            if (this.product.articles) {
              let m = this.product.articles.findIndex(
                (elm) => elm.links.length > 0
              );
              if (m >= 0) {
                this.linked = true;
                let list = [];
                this.data_load = true;
                let r = await this.requette(CMDS_RCPT, {
                  cmd_det_id: this.editedItem.cmd_det_id,
                });
                this.data_load = false;
                if (r) {
                  list = r.cmds_rcpt;
                }
                this.product.articles[m].links.forEach((element) => {
                  element.id = element.link_id;
                  let i = list.findIndex(
                    (elm) => elm.article_id == element.link_id
                  );
                  if (i >= 0) element.qte_rcpt = list[i].qte_rcpt;
                  this.articles.push(element);
                });
              } else
                this.articles = this.product.articles.filter(
                  (elm) =>
                    elm.tier_id == (this.mvm_type == 19 ? 1 : this.mvm.tier_id)
                );
            }
          } else {
            this.article_field = "article_id";
            if (this.iscmd && !this.isdossier)
              this.articles = this.products.filter(
                (elm) => elm.id == this.editedItem.cmd_det_id
              );
            else
              this.articles = this.products.filter(
                (elm) => elm.produit_id == this.editedItem.produit_id
              );
          }
        } else {
          this.article_field = "id";
          this.articles = this.products.filter(
            (elm) => elm.produit_id == this.editedItem.produit_id
          );
        }
      }
    },
    customFilter(item, queryText) {
      let textlist = [];
      [
        "ref",
        "ref_ext",
        "code",
        "label",
        "box",
        "rack",
        "tier_name",
        "nocmd",
        "date_cmd",
      ].forEach((element) => {
        const text1 = item[element] ? item[element].toLowerCase() : "";
        textlist.push(text1);
      });

      const searchText = queryText.toLowerCase();
      const index = textlist.findIndex((element) => {
        if (element.includes(searchText)) {
          return true;
        }
      });
      return index !== -1;
    },

    selected_right_change(items) {
      this.selected_right = items;
    },

    del_item() {
      this.editedItem.qte =
        this.editedItem.fk_unit == this.editedItem.fk_unit_product
          ? this.sumlist_selected.toFixed(this.precision)
          : this.sumlist_selected.toFixed(2);
    },
    add_item() {
      if (this.editedItem.bylist == "0")
        this.boxheaders[1].select_list = this.boxs_list.filter(
          (elm) => !this.form_list.map((elm) => elm.box_id).includes(elm.id)
        );
    },
    excel_import(items) {
      // let f = items[0];
      if (items.headersequal) {
        let list = [];
        let ok = true;
        for (let index = 0; index < items.results.length; index++) {
          const element = items.results[index];
          let elm = {};
          elm.id = index + 1;
          elm.create_uid = this.$store.state.me.id;
          elm.write_uid = this.$store.state.me.id;
          elm.cmd_id = this.editedItem.cmd_id;
          let l = this.colisheaders.filter((elm) => elm.edit == true);
          for (let i = 0; i < l.length; i++) {
            let v =
              typeof element[l[i].text] == "string"
                ? element[l[i].text].trim()
                : element[l[i].text];
            if (l[i].value == "qte" && typeof element[l[i].text] != "number") {
              this.snackbar_text =
                "Valeur incorrecte pour " +
                l[i].text +
                " - ligne " +
                (index + 1);
              this.snackbar_color = "error";
              this.snackbar = true;
              ok = false;
              break;
            } else if (
              l[i].value == "ref" &&
              (!element[l[i].text] ||
                String(element[l[i].text]).trim() == "" ||
                element[l[i].text] == null)
            ) {
              this.snackbar_text = "Barcode incorrect - ligne " + (index + 1);
              this.snackbar_color = "error";
              this.snackbar = true;
              ok = false;
              break;
            } else if (
              l[i].slot == "date" &&
              !(element[l[i].text] instanceof Date)
            ) {
              this.snackbar_text =
                "Date incorrecte pour " + l[i].text + " - ligne " + (index + 1);
              this.snackbar_color = "error";
              this.snackbar = true;
              ok = false;
              break;
            } else if (
              l[i].value == "box_id" &&
              this.boxs_list.findIndex(
                (elm) => elm.box == element[l[i].text]
              ) == -1
            ) {
              this.snackbar_text =
                this.depot.box + " introuvable" + " - ligne " + (index + 1);
              this.snackbar_color = "error";
              this.snackbar = true;
              ok = false;
              break;
            } else {
              if (element[l[i].text] || element[l[i].text] == 0) {
                if (element[l[i].text])
                  if (l[i].value == "qte") {
                    let a = parseFloat(element[l[i].text]);
                    elm[l[i].value] = a;
                  } else if (l[i].slot == "date")
                    elm[l[i].value] = v.toISOString().slice(0, 10);
                  else if (l[i].value == "box_id") {
                    let j = this.boxs_list.findIndex(
                      (elm) => elm.box == element[l[i].text]
                    );
                    if (j >= 0) elm[l[i].value] = this.boxs_list[j].id;
                  } else elm[l[i].value] = v.toString();
              }
            }
          }

          list.push(elm);
        }
        if (ok) {
          this.form_list = list;
          this.editedItem.qte =
            this.editedItem.fk_unit == this.editedItem.fk_unit_product
              ? this.sumlist_selected.toFixed(this.precision)
              : this.sumlist_selected.toFixed(2);
          this.frml++;
        }
      } else {
        this.snackbar_text = "format fichier incompatible!";
        this.snackbar_color = "error";
        this.snackbar = true;
      }
    },

    init_list() {
      this.colisheaders = [];
      this.colisheaders2.forEach((element) => {
        this.colisheaders.push(element);
      });
      let u = "";
      let z;
      if (this.units)
        z = this.units.findIndex((elm) => elm.id == this.editedItem.fk_unit);
      if (z >= 0) u = "(" + this.units[z].name + ")";
      this.colisheaders[2].text = "Quantité " + u;
      this.colisheaders[2].rules = [(v) => !!v || "Quantité obligatoire"];
      this.colisheaders[2].dec = this.precision;
      let colis_attributs = [];
      if (this.mvm_type == 1 || this.mvm_type == 19) {
        colis_attributs = this.editedItem.tabcolisattributs
          ? this.editedItem.tabcolisattributs
          : this.editedItem.colisattributs
          ? this.editedItem.colisattributs
          : [];
      }

      for (let index = 0; index < colis_attributs.length; index++) {
        const element = colis_attributs[index];

        let i = this.attributs_list.findIndex((elm) => elm.id == element);
        if (i >= 0) {
          let ls = [];
          this.attributs_list[i].attributvalues.forEach((element) => {
            ls.push(element.name);
          });
          let elm = {
            text: this.attributs_list[i].caption
              ? this.attributs_list[i].caption
              : this.attributs_list[i].name,
            value: "ref" + index,
            selected: true,
            edit: true,
            readonly: false,
            rules: this.attributs_list[i].is_null
              ? []
              : [
                  (v) =>
                    !!v ||
                    (this.attributs_list[i].caption
                      ? this.attributs_list[i].caption
                      : this.attributs_list[i].name) + " obligatoire",
                ],
            key: this.attributs_list[i].key,
            type:
              this.attributs_list[i].attributvalues.length > 0
                ? "autocomplete"
                : "text",
            select_list: ls,
            show_list: ls,
            sm: 6,
            md: 6,
          };
          this.colisheaders.splice(index + 2, 0, elm);
        }
      }
    },

    async list_mvm() {
      this.form_list = [];

      if (this.editedItem.id >= 0) {
        this.listload = true;
        let v = {
          mvm_detail_id: this.editedItem.id,
          buy: 1,
        };
        let r = await this.requette(PRODUCT_LIST_MVM, v);
        if (r) {
          this.form_list = r.product_list_mvm;
          this.frml++;
          this.editedItem.qte =
            this.editedItem.fk_unit == this.editedItem.fk_unit_product
              ? this.sumlist_selected.toFixed(this.precision)
              : this.sumlist_selected.toFixed(2);
          this.listload = false;
        }
      }
    },

    async maj(query, v, refresh) {
      let r;
      this.progress = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          if (refresh) {
            this.$store.dispatch("Changed", true);
            this.$emit("change");
          }
          r = { ok: true, data: data.data };
          this.progress = false;
        })
        .catch((error) => {
          r = { ok: false, error: error };
          this.progress = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    async requette(query, v) {
      this.loading = true;
      let r = [];

      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          r = data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },

    ligne_exist(p, d) {
      let i = this.items.filter((elm) => elm.id != this.item.id);
      let e = -1;
      if (p && d) {
        e = i.findIndex(
          (elm) =>
            (this.iscmd && !this.isdossier ? elm.cmd_det_id : elm.article_id) ==
              p && elm.depot_id == d
        );
      }
      return e != -1;
    },

    calc_max_cmd() {
      let i;
      let qte_tmp = 0;

      this.items.forEach((element) => {
        if (
          (this.linked ? element.article_id : element.cmd_det_id) ==
            (this.linked
              ? this.editedItem.article_id
              : this.editedItem.cmd_det_id) &&
          element.id != this.editedItem.id
        )
          qte_tmp = qte_tmp + parseFloat(element.qte);
      });

      if (this.articles)
        i = this.articles.findIndex(
          (elm) => elm[this.article_field] == this.editedItem.article_id
        );
      if (i >= 0) {
        this.editedItem.label = this.articles[i].label_ext;
        this.editedItem.ref_ext = this.articles[i].ref_ext;
        this.editedItem.tobuy = this.articles[i].tobuy;

        this.editedItem.product_name = this.articles[i].label;
        this.editedItem.tomanufacture = this.articles[i].tomanufacture;

        if (this.importdetails.length > 0) {
          let j = this.importdetails.findIndex(
            (elm) => elm.article_id == this.editedItem.article_id
          );
          this.qte_max =
            this.importdetails[j].qte -
            (this.importdetails[j].qte_rcpt
              ? this.importdetails[j].qte_rcpt
              : 0) -
            qte_tmp +
            (this.editedItem.qte ? this.editedItem.qte : 0);
          this.qte_rst =
            this.editedItem.fk_unit == this.editedItem.fk_unit_product
              ? this.qte_max.toFixed(this.precision)
              : this.qte_max;
          this.editedItem.pu = this.importdetails[j].pu_calc;
        } else {
          if (this.mvm_type == 1)
            this.qte_liv = this.product.qte_liv ? this.product.qte_liv : 0;
          if (this.mvm_type == 19 && !this.linked)
            this.qte_liv = this.product.qte_rcpt ? this.product.qte_rcpt : 0;
          this.qte_ret = this.articles[i].qte_ret
            ? this.articles[i].qte_ret
            : 0;

          this.tol = (this.qte * this.tol) / 100;

          this.qte_rst = parseFloat(
            this.editedItem.fk_unit == this.editedItem.fk_unit_product
              ? (this.qte - this.qte_liv - qte_tmp).toFixed(this.precision)
              : this.qte - this.qte_liv - qte_tmp
          );
          if (this.linked) {
            let ratio = this.editedItem.ratio ? this.editedItem.ratio : 1;
            this.qte_max =
              this.qte * ratio -
              this.qte_liv +
              this.tol -
              qte_tmp +
              (this.editedItem.qte ? this.editedItem.qte : 0);
          } else
            this.qte_max =
              this.qte -
              this.qte_liv +
              this.tol -
              qte_tmp +
              (this.editedItem.qte ? this.editedItem.qte : 0);
        }
      }
    },

    produit_change() {
      this.qte_max = 0;
      if (this.editedItem.id < 0) {
        this.editedItem.qte = 0;
        this.$refs.form.resetValidation();
      }
      let i;
      if (this.isdossier)
        i = this.products.findIndex(
          (elm) => elm.cmd_detail_id == this.editedItem.cmd_det_id
        );
      else if (this.iscmd)
        i = this.products.findIndex(
          (elm) => elm.id == this.editedItem.cmd_det_id
        );
      else
        i = this.products.findIndex(
          (elm) => elm.produit_id == this.editedItem.produit_id
        );
      if (i >= 0) {
        this.product = this.products[i];
        this.editedItem.tabcolisattributs = this.products[i].tabcolisattributs;
        this.editedItem.colisattributs = this.products[i].colisattributs;
        this.editedItem.cmd_id = this.products[i].cmd_id;
        this.editedItem.bylist = this.products[i].bylist;
        this.colisheaders2[2].qte_max = this.product.maxvalue
          ? this.product.maxvalue
          : 0;

        this.colisheaders2[2].qte_min = this.product.minvalue
          ? this.product.minvalue
          : 0;
        this.precision =
          this.products[i].decimal >= 0 ? this.products[i].decimal : 2;
        if (this.mvm_type == 19)
          this.tol = this.products[i].tol_rcpt ? this.products[i].tol_rcpt : 0;
        else this.tol = this.products[i].tol_qte ? this.products[i].tol_qte : 0;
        this.editedItem.is_expire = this.products[i].is_expire;

        this.colisheaders2[3].hiden = this.editedItem.is_expire == 0;
        this.colisheaders2[3].edit = this.editedItem.is_expire == 1;
        this.editedItem.factor = this.products[i].factor
          ? this.products[i].factor
          : 1;
        this.editedItem.product_factor = this.products[i].factor;
        this.unit_change();
        if (this.iscmd || this.isdossier) {
          if (this.editedItem.id < 0) {
            this.editedItem.produit_id = this.products[i].produit_id;
            this.editedItem.pu = this.products[i].pu;
            this.editedItem.fk_unit = this.products[i].fk_unit;
            this.editedItem.tva_tx = this.products[i].tva_tx;
          }
          this.qte = this.products[i].qte ? this.products[i].qte : 0;
          if (this.editedItem.id < 0 && this.editedItem.bylist == "0") {
            this.editedItem.qte = this.qte - this.products[i].qte_liv;
            this.$refs.form.resetValidation();
          }
        } else {
          if (this.editedItem.id < 0)
            if (this.mvm_type == 18 || this.mvm_type == 19)
              this.editedItem.fk_unit = this.products[i].fk_unit;
            else this.editedItem.fk_unit = this.products[i].fk_unit_buy;
          this.editedItem.fk_unit_buy = this.products[i].fk_unit_buy;
          this.editedItem.fk_unit_product = this.products[i].fk_unit;
          if (this.editedItem.id < 0 && this.mvm_type == 1)
            this.editedItem.pu = this.products[i].prixachat;
          if (this.editedItem.id < 0 && this.mvm_type != 1)
            this.editedItem.pu = this.products[i].prixrevient
              ? this.products[i].prixrevient
              : 1;
          if (this.products[i].service == 1 && !this.editedItem.tva_tx)
            this.editedItem.tva_tx =
              this.$store.state.options[0].default_service_tva;
          if (this.products[i].service == 0 && !this.editedItem.tva_tx)
            this.editedItem.tva_tx =
              this.$store.state.options[0].default_product_tva;
        }
        this.editedItem.service = this.products[i].service;

        this.get_units();
        this.set_articles();
      }

      if (this.articles)
        if (
          (this.articles.length == 1 && this.item.id < 0) ||
          this.item.id > 0
        ) {
          if (this.item.id < 0)
            this.editedItem.article_id = this.articles[0][this.article_field];
          this.article_change();
        }
      if ((!this.iscmd || !this.isdossier) && this.$refs.pu) {
        this.$refs.form.resetValidation();
        this.$refs.pu.focus();
      }
      if ((this.iscmd || this.isdossier) && this.$refs.qte) {
        this.$refs.form.resetValidation();
        this.$refs.qte.focus();
      }
    },
    async article_change() {
      let i;

      if (this.articles)
        i = this.articles.findIndex(
          (elm) => elm.id == this.editedItem.article_id
        );
      if (i >= 0) {
        if (this.linked) {
          //this.editedItem.colisattributs = this.articles[i].colisattributs;
          //this.editedItem.bylist = this.articles[i].bylist;
          this.editedItem.ratio = this.articles[i].ratio;
          this.editedItem.pu = this.articles[i].prixrevient
            ? this.articles[i].prixrevient
            : 1;
          this.qte_liv = this.articles[i].qte_rcpt
            ? this.articles[i].qte_rcpt
            : 0;
          this.colisheaders2[2].qte_max = this.articles[i].maxvalue
            ? this.articles[i].maxvalue
            : 0;

          this.colisheaders2[2].qte_min = this.articles[i].minvalue
            ? this.articles[i].minvalue
            : 0;
          this.precision =
            this.articles[i].decimal >= 0 ? this.articles[i].decimal : 2;
        }
        this.editedItem.code =
          this.mvm_type == 18 || this.mvm_type == 19
            ? this.articles[i].code_article
            : this.articles[i].code;

        if (this.articles[i].depot_id && this.editedItem.id < 0) {
          this.mvm_to = this.articles[i].depot_id;
        }
      }
      this.calc_max_cmd();
      if (this.depots.length == 1 && this.editedItem.id < 0) {
        this.mvm_to = this.depots[0].id;
        this.depot_change();
      }
      if (this.editedItem.bylist == "1" && this.editedItem.id > 0) {
        await this.list_mvm();
      }
    },
    verifie_double() {
      let ok = true;
      for (let index = 0; index < this.form_list.length; index++) {
        const element = this.form_list[index];
        let v = "";

        for (
          let i = 1;
          i <
          this.colisheaders.filter(
            (elm) => elm.value != "ref" && elm.value != "code"
          ).length;
          i++
        ) {
          //verification double dans la list actuelle
          v =
            typeof element[this.colisheaders[i].value] == "string"
              ? element[this.colisheaders[i].value].trim()
              : element[this.colisheaders[i].value];
          if (
            this.colisheaders[i].key == 1 ||
            this.colisheaders[i].value == "ref"
          ) {
            let list2 = this.form_list.filter(
              (elm) =>
                (typeof elm[this.colisheaders[i].value] == "string"
                  ? elm[this.colisheaders[i].value].trim()
                  : elm[this.colisheaders[i].value]) == v
            );

            if (list2.length > 1) {
              this.snackbar_text =
                this.colisheaders[i].text +
                " " +
                element[this.colisheaders[i].value] +
                " dupliqué";
              this.snackbar_color = "error";
              this.snackbar = true;
              ok = false;
              break;
            }
          }
        }
      }
      return ok;
    },
    async close() {
      if (this.form_list.length > 0 && this.item.id < 0) {
        if (
          await this.$refs.confirm.open(
            "Confirmation",
            "Etês-vous sûr de vouloir annuler"
          )
        )
          this.$emit("close", this.items);
      } else this.$emit("close", this.items);
    },
    numberWithSpace: function (x) {
      return x != null
        ? x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ")
        : "0";
    },
    verifie_attribut() {
      let ok = true;
      let l = this.colisheaders.filter((elm) => elm.edit == true);
      for (let index = 0; index < this.form_list.length; index++) {
        const element = this.form_list[index];

        for (let i = 0; i < l.length; i++) {
          if (!element[l[i].value] && l[i].value != "comment") {
            this.snackbar_text =
              l[i].text + " manquant! Ligne " + (parseInt(index) + 1);
            this.snackbar_color = "error";
            this.snackbar = true;
            ok = false;
            break;
          }
        }
        if (this.editedItem.is_expire == "1" && !element.expire_date) {
          this.snackbar_text =
            "Date péremption manquante! Ligne " + (parseInt(index) + 1);
          this.snackbar_color = "error";
          this.snackbar = true;
          ok = false;
          break;
        }
      }
      return ok;
    },

    async save() {
      if (this.$refs.form.validate()) {
        let ok = true;
        //verfier code liste
        // if (
        //   this.editedItem.bylist == "1" &&
        //   this.mvm_type != 1 &&
        //   this.mvm_type != 17
        // ) {
        //   let j = this.form_list.findIndex(
        //     (elm) => elm.ref == null || elm.ref == ""
        //   );
        //   if (j >= 0) {
        //     this.snackbar_text =
        //       "Code article N° " + parseInt(j + 1) + " manquant!!--verifier";
        //     this.snackbar_color = "error";
        //     this.snackbar = true;
        //     ok = false;
        //   }
        ok = this.verifie_double();
        // }
        //ok = this.verifie_double();
        //if (this.editedItem.bylist == "1" && this.mvm_type == 1)
        //         ok = this.verifie_attribut();
        if (ok) {
          this.save_disable = true;
          let pl = [];
          let boxs = [];
          if (this.editedItem.bylist == "1") {
            let l = this.colisheaders.filter((elm) => elm.edit == true);
            for (let index = 0; index < this.form_list.length; index++) {
              const element = this.form_list[index];
              let elm = {};
              if (this.imp) elm.id = index + 1;
              elm.depot_id = this.mvm_to;
              elm.article_id = this.editedItem.article_id;
              for (let i = 0; i < l.length; i++) {
                elm[l[i].value] = element[l[i].value]
                  ? element[l[i].value]
                  : null;
              }
              pl.push(elm);
              //
            }
          }
          if (this.editedItem.bylist == "0" && this.depot.NbRack > 0) {
            for (let index = 0; index < this.form_list.length; index++) {
              const element = this.form_list[index];
              let elm = {};
              elm.article_id = this.editedItem.article_id;
              elm.box_id = element.box_id;
              elm.qte = element.qte;

              boxs.push(elm);
              //
            }
          }
          let i;
          i = this.articles.findIndex(
            (elm) => elm.article_id == this.editedItem.article_id
          );
          if (i >= 0) {
            this.editedItem.product_name = this.articles[i].label;
          }
          let v;

          v = {
            mvmdetail: [
              {
                mvm_id: this.item.mvm_id,
                pu: this.editedItem.pu,
                qte: parseFloat(this.editedItem.qte),
                fk_unit: this.editedItem.fk_unit,
                factor: this.editedItem.factor,
                tva_tx: this.editedItem.tva_tx,
                remise: parseFloat(this.editedItem.remise),
                label: null,
                description: this.editedItem.description,
                depot_id: this.editedItem.service == "0" ? this.mvm_to : null,
                article_id: this.editedItem.article_id,
                cmd_det_id: this.editedItem.cmd_det_id,
                colis_json: this.editedItem.colis_json
                  ? JSON.stringify(this.editedItem.colis_json)
                  : null,
                //pmp: this.editedItem.pmp,
                comment: this.editedItem.comment,
                write_uid: this.$store.state.me.id,
              },
            ],
            id: this.editedItem.id > 0 ? this.editedItem.id : null,
            factor:
              this.mvm_type == 18 || this.mvm_type == 19
                ? 1
                : this.editedItem.factor,
            precision: this.precision ? this.precision : 2,
            inserts_list: pl.length > 0 ? pl : null,
            boxs: boxs.length > 0 ? boxs : null,
          };

          let r = await this.maj(MVM_DETAIL_UPDATE, v);
          if (r.ok) {
            if (this.editedItem.id > 0)
              this.items.splice(this.item.index, 1, this.editedItem);
            else {
              this.editedItem.id = r.data.UpdateMvmDetail;
              this.items.push(this.editedItem);
            }
            this.$emit("change", this.editedItem);
            this.$store.dispatch("Changed", true);
            this.$emit("close", this.items);
          } else {
            let s = r.error.graphQLErrors[0].debugMessage;
            if (s)
              if (
                s.search("1062") > 0 &&
                s.search("products_list_index03") > 0
              ) {
                let s1 = s.indexOf("'", 0);
                let s2 = s.indexOf("'", s1 + 1);
                let k = s.substring(s1 + 1, s2);
                this.snackbar_text = "le code : " + k + " existe déja";
              } else this.snackbar_text = "Erreur d'enregistrement inconnue ";
            else this.snackbar_text = "Erreur d'enregistrement inconnue ";
            this.add_disable = false;
            this.snackbar_color = "error";
            this.snackbar = true;
          }
          this.save_disable = false;
        }
      }
    },
  },
};
</script>
